import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import BasicModal from './Model';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  }));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
    border: 0,
  },
  }));
  export default function Admin() {
    const [open, setOpen] = React.useState(false);

    const [data,setData]=React.useState()
    const [datas,setDatas]=React.useState({user:{},status:{}})
    const handleOpen = () => setOpen(true);
   const getStatus=async(id)=>{
   
    const res =await axios.get(`https://formpanel-1.onrender.com/api/v1/`+id)
    if(res){
      console.log(res.data.user)
      console.log(res.data.status)
      // alert("Thank you "+res.data.user.Name)
      setDatas({user:res.data.user,status:res.data.status})
      handleOpen()
    }
   }
    
         const fetchData=async()=>{

           await axios.get('https://formpanel-1.onrender.com/api/v1/post').then((data)=> setData(data.data.data)).catch((error)=>console.log(error))
      //  console.log(data)
          }
      React.useEffect(()=>{
fetchData();
// eslint-disable-next-line
      },[data]);
          
              // console.log(data)
           return (
            <div className='pt-[5rem] '>
         

    <TableContainer component={Paper} className='px-[3%]'>
      <div className='relative'>

        <h1 className='text-[25px] font-bold text-orange-400 text-center uppercase py-[2rem]'>Students Information</h1>
        <div className=' absolute right-0 top-[2rem]'>
          {/* <a href="/status">
  <button className='bg-[#252525] px-[2rem] py-4 w-fit hover:bg-[#f81caf] transition-all text-white font-semibold tracking-widest'>Check Status</button>
          </a> */}

</div>
      </div>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align="right">Email</StyledTableCell>
            <StyledTableCell align="right">Phone</StyledTableCell>
            <StyledTableCell align="right">Address</StyledTableCell>
            <StyledTableCell align="right">Course</StyledTableCell>
            <StyledTableCell align="right">TransactionId</StyledTableCell>
            <StyledTableCell align="right">Status</StyledTableCell>
            <StyledTableCell align="right">WhatsApp</StyledTableCell>
          </TableRow>
        </TableHead>
        {data?<TableBody>
          {data?.length>0 && data?.map((row) => 
            <StyledTableRow key={row.Name}>
              <StyledTableCell component="th" scope="row">
                {row.Name}
              </StyledTableCell>
              <StyledTableCell align="right">{row.Email}</StyledTableCell>
              <StyledTableCell align="right">{row.Phone}</StyledTableCell>
              <StyledTableCell align="right">{row.Address}</StyledTableCell>
              <StyledTableCell align="right">{row.course}</StyledTableCell>
              <StyledTableCell align="right">{row.TId}</StyledTableCell>
            
              <StyledTableCell align="right">
                <h1
                //  href={`tel:${row.Phone}`}
                onClick={()=>getStatus(row?.TId)}
                
                  className='text-white  w-[100%] font-bold bg-blue-500 cursor-pointer px-3 py-2'>
                 
                 Check<span className='ml-2'/>Status
                  </h1> </StyledTableCell>
            
              <StyledTableCell align="right"><a href={`https://wa.me/${row.Phone}`} className='text-white font-bold bg-green-500 px-3 py-2'>WhatsApp</a></StyledTableCell></StyledTableRow>
          )}
        </TableBody>:
        <div className="w-[100%] flex items-center justify-center">
        <h1 className="text-center">
            Loading..
            </h1>
        </div>
        }
      </Table>
    </TableContainer>
<BasicModal open={open} setOpen={setOpen} data={datas}/>
    </div>
  );
}