import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export default function Status() {
  const [data, setData] = React.useState();

  const fetchData = async () => {
    await axios
      .get("https://formpanel-1.onrender.com/api/v1/post/statu")
      .then((data) => setData(data.data.data))
      .catch((error) => console.log(error));
    console.log(data);
  };
  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [data]);

  console.log(data);
  return (
    <div className="pt-[5rem] ">
      <TableContainer component={Paper} className="px-[3%]">
        <div className="relative">
          <h1 className="text-[25px] font-bold text-orange-400 text-center uppercase py-[2rem]">
           Payment Status
          </h1>
          
        </div>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Status</StyledTableCell>

              <StyledTableCell align="right">TransactionId</StyledTableCell>
              <StyledTableCell align="right">Message</StyledTableCell>
              {/* <StyledTableCell align="right">WhatsApp</StyledTableCell> */}
            </TableRow>
          </TableHead>
          {data?<TableBody>
            {data?.length > 0 &&
              data?.map((row) => (
                <StyledTableRow key={row.TId}>
                  <StyledTableCell component="th" scope="row">
                    {row.Status ? "True" : "False"}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.TId}</StyledTableCell>
                  <StyledTableCell align="right">{row.Message}</StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>:<div className="w-[100%] flex items-center justify-center">
            <h1 className="text-center">
                Loading..
                </h1>
            </div>}
        </Table>
      </TableContainer>
    </div>
  );
}
