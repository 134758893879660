import React, { useState } from "react";
import {
  FaBookReader,
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaYoutube,
} from "react-icons/fa";
import { FiAlignRight } from "react-icons/fi";
import {
  IoCartOutline,
  IoCloseOutline,
  IoLocationSharp,
} from "react-icons/io5";
import { MdOutlineEmail } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { uiAction } from "./store/ui-slice";
import { Divider } from "@mui/material";
import { motion } from "framer-motion";

export default function Navbar() {
  const [stick, setStick] = useState();
  const [menu, setMenu] = useState(false);
  const [course,setCourse]=useState(false)
  window.onscroll = function () {
    myFunction();
  };
  // var header=document.getElementsByClassName('nav');
  // var sticky=header.offsetTop;
  function myFunction() {
    if (window.scrollY > 100) {
      setStick(true);
      console.log(stick);
    } else {
      setStick(false);
    }

    console.log(window.scrollY);
  }
  function openNav() {
    document.getElementById("myNav").style.width = "30%";
  }

  function closeNav() {
    document.getElementById("myNav").style.width = "0%";
  }
  const dispatch = useDispatch();
  const quantity = useSelector((state) => state.cart.totalQuantity);
  const toggleHandler = () => {
    dispatch(uiAction.toggle());
  };
  const toggle = useSelector((state) => state.ui.cartIsVisible);
  const itemm = useSelector((state) => state.cart.item);
  console.log(itemm);
  return (
    <div
    
      className={`w-[100vw] flex fixed  overflow-x-hidden justify-center z-100 ${
        stick
          ? "fixed w-full bg-[#ffffffd5] shadow-md"
          : "fixed w-[100%] overflow-x-hidden"
      }`}
      style={{ zIndex: 50 }}
      onMouseLeave={()=>setCourse(false)}
    >
      <div className="max-w-[1350px] py-[1rem] px-[5%] flex w-[100%] justify-center items-center">
        <div className="flex w-[100%] justify-between items-center">
          <h1 className="text-3xl font-bold uppercase ">
            <img
              src={require("../Assets/full logo.png")}
              alt=""
              className="max-md:h-[2.5rem] md:h-[3rem]"
            />
          </h1>
          <div className="flex  items-center gap-10">
            <ul className="flex max-xl:hidden gap-10 tracking-[0.15em] uppercase text-lg  font-semibold">
              <li>
                <Link to="/" onMouseLeave={()=>setCourse(false)} className="hover:text-[#923559] transition-all">Home</Link>
              </li>
              <li>
                <Link to="/about"  onMouseLeave={()=>setCourse(false)}  className="hover:text-[#923559] transition-all">About</Link>
              </li>
              <li>
                <Link to="/course" onMouseEnter={()=>setCourse(true)} className="hover:text-[#923559] transition-all">Courses</Link>
              </li>
              <li>
                <Link to="/contact"  onMouseLeave={()=>setCourse(false)} className="hover:text-[#923559] transition-all">Contact</Link>
              </li>
            </ul>
            <div
             className="text-[2rem] flex gap-4 font-semibold ">
              <div className="relative">
                <IoCartOutline
                   onClick={()=>dispatch(uiAction.toggle(true))}
                  className="cursor-pointer hover:text-[#aa315c] transition-all"
                />
                <div className="absolute -top-3 right-[-1rem] text-xs ">
                  <div className="bg-[#501937] text-xs text-white font-semibold px-2 py-1 rounded-full">
                    {quantity}
                  </div>
                </div>
              </div>
              {!menu && (
                <FiAlignRight
                  onClick={() => setMenu(true)}
                  className="xl:hidden cursor-pointer transition-all hover:text-[#e93991]"
                />
              )}
              {menu && (
                <IoCloseOutline
                  onClick={() => setMenu(false)}
                  className="xl:hidden cursor-pointer transition-all hover:text-[#f13cca]"
                />
              )}
              <FiAlignRight
                onClick={openNav}
                className="max-xl:hidden cursor-pointer hover:text-[#aa315c] transition-all"
              
              />
            </div>
          </div>
        </div>
      </div>

    {menu &&  <motion.div
        animate={{ width: menu ? "100%" : 0, opacity: menu ? 1 : 0 }}
        transition={{ duration: 0.3, type: "tween" }}
        className="fixed xl:hidden px-[5%] text-white py-[2rem] z-50 w-[100%] mt-[5rem] h-fit rounded-b-md bg-[#64233e]"
      >
        <div className="w-[100%] flex flex-col gap-2">
          <div className="flex flex-col gap-3">
            <h1 className="text-[1.2rem] font-semibold">
              {" "}
              <a href="/">Home</a>
            </h1>
            <div className="border-[1px] border-[#ffffff]" />
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[1.2rem] font-semibold">
              {" "}
              <a href="/about">About</a>
            </h1>
            <div className="border-[1px] border-[#fdfdfd]" />
          </div>
          <div className="flex flex-col gap-3">
            <h1 className="text-[1.2rem]  font-semibold">
              {" "}
              <a href="/course">Course</a>
            </h1>
            <div className="border-[1px] border-[#ffffff]" />
          </div>
          <div className="flex flex-col gap-1">
            <h1 className="text-[1.2rem] font-semibold">
              {" "}
              <a href="/contact">Contact</a>
            </h1>
            {/* <div className='border-[1px] border-[#383737]'/> */}
          </div>
        </div>
      </motion.div>}
      <div id="myNav" className="overlay">
        <h1 className="closebtn text-black cursor-pointer" onClick={closeNav}>
          <IoCloseOutline className="text-[3rem]" />
        </h1>
        <div className="flex flex-col py-[6em] px-[2rem]  gap-5">
          <div className="flex flex-col text-[#252525]">
            <h1 className="text-[1.5rem] tracking-wider font-semibold mb-2">
              <img
                src={require("../Assets/full logo.png")}
                alt="logos"
                className="h-[2rem]"
              />
            </h1>
            <p className="text-[1rem] tracking-wider">
            We’re here to assist you with all your queries and ensure you have the best learning experience!
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex gap-5 text-[1.2rem] uppercase items-center">
              <IoLocationSharp className="text-[1.3rem]" />{" "}
              <h1 className="hover:text-[#ee3aa3] transition-all font-semibold tracking-widest">
                Location
              </h1>
            </div>
            <a href="https://maps.app.goo.gl/SiziKXftEChQtrQj8" target="_blank" >
            <p className="hover:text-[#972a73] text-[#252525] hover:font-[600] cursor-pointer transition-all text-[1rem]">
            Nilgiri Apartment, Behind Royal 
              <br />
              Enfield Showroom, Hubli-582101.
            </p>
            </a>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex gap-5 text-[1.2rem] uppercase items-center">
              <FaPhoneAlt className="text-[1.3rem]" />{" "}
              <h1 className="hover:text-[#ee3aa3] transition-all font-semibold tracking-widest">
                Phone
              </h1>
            </div>
            <a href="tel:8197438906" className="hover:text-[#972a73] text-[#252525] hover:font-[600] cursor-pointer transition-all text-sm">

            <p
            className="hover:text-[#972a73] text-[#252525] hover:font-[600] cursor-pointer transition-all text-[1rem]">8197438906</p>
            </a>
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex gap-5 text-[1.2rem] uppercase items-center">
              <MdOutlineEmail className="text-[1.3rem]" />{" "}
              <h1 className="hover:text-[#ee3aa3] transition-all font-semibold tracking-widest">
               Email
              </h1>
            </div>
            <a href="mailto:learnersitacademy@gmail.com">

            <p  className="hover:text-[#972a73] text-[#252525] hover:font-[600] cursor-pointer transition-all text-[1rem]">learnersitacademy@gmail.com</p>
            </a>
          </div>
          <div className="flex gap-1">
              <a href="https://www.linkedin.com/company/learnersitacademy/">
            <div className="bg-blue-600 cursor-pointer  text-white rounded-md p-2 text-[1.2rem]">
              

               <FaLinkedinIn />
            </div>
              </a>
              <a href="https://www.instagram.com/learnersitacademy/p/C5Fg5fCvd7G/">
            <div className="bg-pink-600  cursor-pointer text-white rounded-md p-2 text-[1.2rem]">

              <FaInstagram />
            </div>
              </a>
            {/* <div className="bg-red-600  cursor-pointer text-white rounded-md p-2 text-[1.2rem]">
              <FaYoutube />
            </div> */}
          </div>
        </div>
      </div>

      {course && <div className="fixed flex gap-5 max-xl:hidden top-[4rem] h-[17rem] w-[85%]  z-50 bg-white shadow-md">
        <div className="pl-[3rem] w-[75%] py-6 grid grid-cols-3 gap-x-1 text-md gap-y-4 uppercase tracking-[2px]">
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/1'} className="hover:text-[#c24692] transition-all "> Java Full Stack Course </Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/2'} className="hover:text-[#c24692] transition-all"> Frontend Developer Course</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/3'} className="hover:text-[#c24692] transition-all">Backend Developer Course</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/4'} className="hover:text-[#c24692] transition-all">Data Science Course</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/11'} className="hover:text-[#c24692] transition-all">Data Anaysis Course</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/5'} className="hover:text-[#c24692] transition-all">Ui/Ux Design Course</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/6'} className="hover:text-[#c24692] transition-all">Human Resource(HR)</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/7'} className="hover:text-[#c24692] transition-all">Tally Prime</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/8'} className="hover:text-[#c24692] transition-all">Advance Excel</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/9'} className="hover:text-[#c24692] transition-all">Digital Marketing</Link></h1>
          </div>
          <div className="flex gap-3 items-center">
            <FaBookReader className="text-[1rem] " />
            <h1 className="font-semibold text-sm"><Link to={'course/10'} className="hover:text-[#c24692] transition-all">Basic Computer</Link></h1>
          </div>
          {/* <div className="flex gap-3 items-center">
           <div className="bg-[#252525] text-xs cursor-pointer hover:text-[#c24692] text-white font-semibold tracking-wider px-2 py-3"><Link to={'course'} className="">View All Courses</Link></div>
          </div> */}
        </div>
        <div className="py-6 w-[30%] pr-[3rem] grid-rows-4">
          <img src={require('../Assets/4.png')} alt="" className="w-[100%] h-[100%] object-cover" />
        </div>
      </div>}

      <motion.div
        animate={{ height: toggle ? "70%" : 0, opacity: toggle ? 1 : 0 }}
        transition={{ duration: 0.3, type: "tween" }}
        className="fixed xl:w-[30%] md:w-[50%] max-md:w-[100%] max-md:top-[5em] bg-[#ffffff] md:top-[6rem] z-40 right-0  h-[100%] "
      >
        <div className="flex flex-col gap-y-5 w-[100%] py-[2rem] px-[5%]">
          {itemm.map((data, index) => (
            <div key={data.id} className="flex flex-col gap-y-3">
              <div className="w-[100%] flex justify-around text-black gap-x-3 items-center">
                <h1 className="text-sm ">{data.name}</h1>
                <span className="text-sm">{data.price}</span>
                <Link
                  to={`/checkout/${data.id}`}
                  className="bg-[#2b101a] text-white px-2 py-3 text-xs font-semibold tracking-wider"
                >
                  CheckOut
                </Link>
              </div>
              <Divider />
            </div>
          ))}
          {itemm.length <= 0 && (
            <h1 className="text-[#252525] font-semibold text-sm items-center flex justify-center">
              No Items Present
            </h1>
          )}
        </div>
      </motion.div>
    </div>
  );
}
