import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import HomePage from './components/HomePage.jsx';
// import About from './components/AboutPage/Banner.jsx'
// import Contact from './components/ContactPage/Banner.jsx'
import Navbar from './components/Navbar.jsx';
// import Course from './components/CoursePage/Banner.jsx'
import { lazy } from 'react';
import { Suspense } from'react';
import { RiLoader5Fill } from 'react-icons/ri';
import Admin from './components/Admin/Admin.js';
import Status from './components/Admin/Status.js';
import Privacy from './components/Privacy.jsx';
// import Success from './components/Success/Success.jsx';
const HomePage=lazy(()=>import('./components/HomePage.jsx'))
const Course=lazy(()=>import('./components/CoursePage/Banner.jsx'))
const Contact=lazy(()=>import('./components/ContactPage/Banner.jsx'))
const Success =lazy(()=>import('./components/Success/Success.jsx'))
const About=lazy(()=>import('./components/AboutPage/Banner.jsx'))
const Fail = lazy(()=>import('./components/Success/Fail.jsx'))
const CourseDetails=lazy(()=>import('./components/CourseDetails/Banner.jsx'))
const Checkout=lazy(()=>import('./components/Checkout/Index.jsx'))
const Tearm=lazy(()=>import('./components/Tearm.jsx'))
const Refund=lazy(()=>import('./components/Refund.jsx'))
function App() {
  return (
    <div className=''>
    <BrowserRouter>
    <Navbar/>
    <Suspense fallback={<div className='text-[3rem] font-semibold flex justify-center items-center w-[100vw] h-[100vh]'><RiLoader5Fill className='animate-spin' /></div>}>
    <Routes>
      <Route path='/' element={<HomePage/>}/>
      <Route path='/admin' element={<Admin/>}/>
      <Route path='/status' element={<Status/>}/>

      <Route path='/about' element={<About/>}/>
      <Route path='/contact' element={<Contact/>}/>
      <Route path='/course' element={<Course/>}/>
      
      <Route path='course/:id' element={<CourseDetails/>}/>
      <Route path='checkout/:id' element={<Checkout/>}/>
      <Route path='success/:id' element={<Success/>}/>
      <Route path='fail' element={<Fail/>}/>
      <Route path='terms' element={<Tearm/>}/>
      <Route path='privacy' element={<Privacy/>}/>
      <Route path='refaund' element={<Refund/>}/>
      
      </Routes>
</Suspense>
 
      </BrowserRouter>
  

    {/* <MoveImage src={'https://onekorse.wpengine.com/wp-content/uploads/2023/02/Circle-Image.png'} alt="Image" config={{ offsetX: 100, offsetY: 100, speed: 0.1 }} /> */}
    </div>
  );
}

export default App;
